.mgCompHeartContainer {
  --heart-color: rgb(255, 91, 137);
  position: relative;
  width: 100%;
  height: 100%;
  transition: 0.3s;
}

.mgCompHeartContainer .checkbox {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 20;
  cursor: pointer;
}

.mgCompHeartContainer .svgContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mgCompHeartContainer .svgOutline {
  width: 100%;
  height: 100%;
  fill: gray;
}

.mgCompHeartContainer .svgFilled {
  fill: var(--heart-color);
  position: absolute;
}

.mgCompHeartContainer .svgFilled {
  animation: keyframes-svg-filled 1s;
  display: none;
}

.mgCompHeartContainer .svgCelebrate {
  position: absolute;
  animation: keyframes-svg-celebrate 0.5s;
  animation-fill-mode: forwards;
  display: none;
  stroke: var(--heart-color);
  fill: var(--heart-color);
  stroke-width: 2px;
}

.mgCompHeartContainer .checkbox:checked ~ .svgContainer .svgOutline {
  fill: var(--heart-color);
}

.mgCompHeartContainer .checkbox:checked ~ .svgContainer .svgFilled {
  display: block;
}

.mgCompHeartContainer .checkbox:checked ~ .svgContainer .svgCelebrate {
  display: block;
}

@keyframes keyframes-svg-filled {
  0% {
    transform: scale(0);
  }

  25% {
    transform: scale(1.2);
  }

  50% {
    transform: scale(1);
    filter: brightness(1.5);
  }
}

@keyframes keyframes-svg-celebrate {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    filter: brightness(1.5);
  }

  100% {
    transform: scale(1.4);
    opacity: 0;
    display: none;
  }
}
